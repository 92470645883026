.heading {
  &.heading-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading-heading {
      text-align: center;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }
    .heading-description {
      text-align: center;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }
  }
  .heading-heading {
    h1 {
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 1.1;
      font-weight: 700;
      font-family: var(--font-family-heading);
      @include media-breakpoint-up(md) {
        font-size: 72px;
        line-height: 1;
        width: 80%;
        letter-spacing: -2px;
      }
      strong {
        font-style: normal;
        color: rgb(255, 84, 0);
      }
    }
  }
  .heading-description {
    width: 100%;
    p {
      font-size: 19px;
      letter-spacing: 0px;
      line-height: 1.45;
      font-family: var(--font-family-base);
      color: var(--color-base-text-2);
      @include media-breakpoint-up(md) {
        font-size: 23px;
        letter-spacing: 0px;
        line-height: 1.45;
        width: 90%;
      }
    }
  }
  .heading-heading + .heading-description {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  .heading-badge {
    font-family: var(--font-family-monospace);
    color: var(--color-primary-bg);
  }
  .heading-image {
    margin-top: 30px;
    img {
      max-width: 100%;
    }
  }
}
